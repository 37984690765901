.wrapper-chat {
  width: calc(25% - 5px);
}
.wrapper-chat-content {
  width: calc(50% - 5px);
}
.wrapper-chat-info {
  width: calc(25% - 5px);
}

.chat-mobile-wrapper {
  display: block;
}

@media only screen and (max-width: 1023px) {
  .chat-mobile-wrapper {
    display: none !important;
  }
  .chat-mobile-wrapper-center {
    float: none;
    text-align: center !important;
  }
  .pinned {
    position: relative;
    top: 0;
    width: 100%;
    a {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .chat-list__item {
    &--counter {
      &__count {
        display: block;
        &-value {
          position: absolute;
          top: -36px;
          left: 100px;
          -webkit-transform: translate(-70px, -20px);
          -moz-transform: translate(-70px, -20px);
          -o-transform: translate(-70px, -20px);
          -ms-transform: translate(-70px, -20px);
          transform: translate(-70px, -20px);
        }
      }
    }
  }
  .wrapper-chat {
    width: 106px;
  }
  .wrapper-chat-content {
    width: calc(50% - 53px);
  }
  .wrapper-chat-info {
    width: calc(50% - 68px);
  }
}

@media only screen and (orientation: portrait) {
  .chat-mobile-wrapper {
    display: none !important;
  }
}

.toggleChat {
  .wrapper-chat {
    width: 106px;
  }
  .wrapper-chat-content {
    width: calc(50% - 53px);
  }
  .wrapper-chat-info {
    width: calc(50% - 68px);
  }

  .pinned {
    position: relative;
    top: 0;
    width: 100%;
    a {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .chat-list__item {
    .chat-mobile-wrapper {
      display: none !important;
    }
    &--counter {
      &__count {
        display: block;
        &-value {
          position: absolute;
          top: -36px;
          left: 100px;
          -webkit-transform: translate(-70px, -20px);
          -moz-transform: translate(-70px, -20px);
          -o-transform: translate(-70px, -20px);
          -ms-transform: translate(-70px, -20px);
          transform: translate(-70px, -20px);
        }
      }
    }
  }
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  padding: 1em;
}
