/* You can add global styles to this file, and also import other style files */
@import 'helper';
@import 'chat';
@import 'variable';

$breakpoint-tablet: 768px;
$breakpoint-tablet2: 1024px;

:root {
  --brand-color: #d20000;
  --background-color: #f4f4f4;
}
body  {
  font-size: 13px !important;
}

// Theme box fixed
.flag-icon {
  display: none;
}
.nav-pills.flex-column .nav-link {
  word-break: break-all;
}

.modal .modal-content {
  .modal-body {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}

body .lp-content .lp-content {
  padding: 0 !important;
}

.navbar-brand {
  background: url('../assets/images/logo.png') no-repeat;
}
.account-brand .navbar-brand {
  background: url('../assets/images/logo-reverse.png') no-repeat;
}

.lp-content .lp-footer {
  display: none;
}

.is-delivered {
  color: $green;
}

.angular-editor-textarea {
  min-height: 200px !important;
}

.ant-steps-item {
  &.ant-steps-item-finish {
    .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
    .ant-steps-item-tail::after {
      background: var(--bs-primary);
    }
  }
}

.confirmation .confirmation-backdrop {
  background: rgba(0, 0, 0, 0.2) !important;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.2) !important;
}

// ng select and x-small button style
.btn {
  &.btn-xs {
    padding: 2px 8px !important;
    font-size: 10px;
  }

  &[disabled] {
    &:hover {
      background-color: var(--bs-secondary) !important;
      color: white;
      border-color: var(--bs-primary) !important;
    }
  }

  &-outline-primary {
    &:hover,
    &:active,
    &:focus {
      background-color: var(--bs-gray-300) !important;
      color: var(--bs-primary) !important;
    }
  }

  &-outline-primary[disabled] {
    background-color: var(--bs-gray-300) !important;
    color: var(--bs-primary) !important;

    &:hover {
      background-color: var(--bs-secondary) !important;
      color: var(--bs-secondary);
    }
  }

  &-warning[disabled] {
    &:hover {
      background-color: var(--bs-warning) !important;
      color: white;
      border-color: var(--bs-warning) !important;
    }
  }

  &-secondary[disabled] {
    &:hover {
      background-color: var(--bs-secondary) !important;
      color: white;
      border-color: var(--bs-secondary) !important;
    }
  }
}

.ng-select {
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: transparent !important;
    box-shadow: 0 0 0.2rem #113059 !important;
  }
  &.ng-select-multiple {
    .ng-value {
      background: var(--bs-primary) !important;
      color: white !important;

      &-icon,
      &-label {
        &:hover {
          background: var(--text-color) !important;
        }
      }
    }
  }
  .ng-select-container {
    border: 2px solid #ebebeb;
    background-color: #f2f2f2;
    min-height: 30px;

    &:focus {
      background-color: #fff !important;
    }
  }
  &.input-validation-error {
    border: 2px solid;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;

    .ng-select-container {
      border-color: transparent;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

// =================================================================

//  data table, dropdown and remove all button style
.ngx-datatable {
  .datatable-header-inner {
    .datatable-header-cell {
      line-height: 2 !important;
      padding: 5px 5px 5px 10px !important;
      font-size: 13px;
    }
  }

  .datatable-body {
    &-cell {
      padding: 5px 5px 5px 10px !important;
      line-height: 2 !important;

      &-label {
        font-size: 13px;
      }
    }
  }
}

.data-tables-remove-all {
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 1;
}

.datatable-body-row.datatable-row-odd {
  background: #f8f8f8;
}

.datatable-checkbox input[type='checkbox']:checked:before {
  border-color: var(--bs-primary);
}

.datatable-checkbox {
  margin-top: 2px;
}

.dropdown-none-caret {
  .dropdown-toggle {
    &:after {
      border: 0;
    }
  }
}
// =================================================================

.group-control {
  &.fixed-error-field {
    .form-control + abp-validation-error {
      width: 100%;
      margin-left: 32px;
    }
  }
  .form-control + abp-validation-error {
    width: 100%;
    margin-left: 32px;
  }
}

lc-root > abp-dynamic-layout > abp-application-layout > div.h-100 {
  overflow-x: hidden;
}

.modal-dialog-scrollable .modal-body,
.modal-dialog-scrollable .modal-content {
  overflow: visible;
}

@media only screen and (max-width: 991.99px) {
  lc-root > abp-dynamic-layout > abp-application-layout > div.h-100 {
    min-height: calc(100vh - 105px) !important;
    max-height: calc(100vh - 105px) !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1366.99px) {
  lc-root > abp-dynamic-layout > abp-application-layout > div.h-100 {
    min-height: calc(100vh - 169px) !important;
    max-height: calc(100vh - 169px) !important;
  }
}

@media only screen and (min-width: 1367px) {
  lc-root > abp-dynamic-layout > abp-application-layout > div.h-100 {
    max-height: calc(100vh - 103px) !important;
    min-height: calc(100vh - 103px) !important;
  }
}
