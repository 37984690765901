// 0 to 10
// T: Top, L: Left, R: Right, B: Bottom

@for $i from 0 through 10 {
  @each $position in ('T', 'R', 'L', 'B') {
    .m#{$position}#{$i*5} {
      @if $position == 'T' {
        margin-top: #{$i * 5}px !important;
      }
      @if $position == 'R' {
        margin-right: #{$i * 5}px !important;
      }
      @if $position == 'B' {
        margin-bottom: #{$i * 5}px !important;
      }
      @if $position == 'L' {
        margin-left: #{$i * 5}px !important;
      }
    }
    .p#{$position}#{$i*5} {
      @if $position == 'T' {
        padding-top: #{$i * 5}px !important;
      }
      @if $position == 'R' {
        padding-right: #{$i * 5}px !important;
      }
      @if $position == 'B' {
        padding-bottom: #{$i * 5}px !important;
      }
      @if $position == 'L' {
        padding-left: #{$i * 5}px !important;
      }
    }
  }
  .p#{$i*5} {
    padding: #{$i * 5}px !important;
  }
  .m#{$i*5} {
    margin: #{$i * 5}px !important;
  }
}

.c-pointer {
  cursor: pointer;
}
.w200 {
  width: 200px !important;
}
.w20 {
  width: 20px;
}
.whiteBg {
  background: white;
}

.white {
  color: white;
}
.scrolling {
  overflow: auto;
}

.bold {
  font-weight: bold;
}

@mixin rtl {
  @at-root [dir='rtl'] #{&} {
    @content;
  }
}

.clearfix {
  clear: both;
}
